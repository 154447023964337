import React from "react";
import { useSelector } from 'react-redux';
import * as userService from "../../../containers/userService";
import { Link, useNavigate } from 'react-router-dom';

const Sidebar = () => {
    const navigate = useNavigate();

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    console.log(isAuthenticated)

    const handleLogout = () => {
        userService.Logout();
    };

    const handleLinkClick = (e) => {
        e.attributes.add('data-unfold-type', 'css-animation')
        e.attributes.add('data-unfold-animation-in', 'fadeInLeft')
        e.attributes.add('data-unfold-animation-out', 'css-fadeOutLeft')
        e.attributes.add('data-unfold-duration', '500')
    }

    return (
        <>
            {/* Categories Sidebar Navigation */}
            <aside id="sidebarContent2" className="u-sidebar u-sidebar__md u-sidebar--left"
                   aria-labelledby="sidebarNavToggler2">
                <div className="u-sidebar__scroller js-scrollbar">
                    <div className="u-sidebar__container">
                        <div className="u-header-sidebar__footer-offset">

                            <div className="u-sidebar__body">
                                <div className="u-sidebar__content u-header-sidebar__content">

                                    <header
                                        className="px-4 px-md-5 py-4 d-flex align-items-center justify-content-between">
                                        <h2 className="font-size-3 mb-0">Pandori(이미지삽입예정)</h2>


                                        <div className="d-flex align-items-center">
                                            <button type="button" className="close ml-auto"
                                                    aria-controls="sidebarContent2"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    data-unfold-event="click"
                                                    data-unfold-hide-on-scroll="false"
                                                    data-unfold-target="#sidebarContent2"
                                                    data-unfold-type="css-animation"
                                                    data-unfold-animation-in="fadeInLeft"
                                                    data-unfold-animation-out="fadeOutLeft"
                                                    data-unfold-duration="500">
                                                <span aria-hidden="true"><i className="fas fa-times ml-2"></i></span>
                                            </button>
                                        </div>
                                    </header>


                                    <div className="zeynep px-3 pt-3 pb-3">
                                        <h2 className="font-size-2 pb-2 border-bottom">카테고리</h2>
                                        <ul>
                                            <li className="font-size-2 mx-2">
                                                <Link className="p-2 px-5" onClick={handleLinkClick} to='/best'>베스트</Link>
                                            </li>
                                            <li className="font-size-2 mx-2">
                                                <Link className="p-2 px-5" onClick={handleLinkClick} to='/new'>New 앨범</Link>
                                            </li>
                                            <li className="font-size-2 mx-2">
                                                <Link className="p-2 px-5" onClick={handleLinkClick} to='/song'>가요</Link>
                                            </li>
                                            <li className="font-size-2 mx-2">
                                                <Link className="p-2 px-5" onClick={handleLinkClick} to='/popzazz'>팝/재즈</Link>
                                            </li>
                                            <li className="font-size-2 mx-2">
                                                <Link className="p-2 px-5" onClick={handleLinkClick} to='/classic'>클래식</Link>
                                            </li>
                                            <li className="font-size-2 mx-2">
                                                <Link className="p-2 px-5" onClick={handleLinkClick} to='/special'>스페셜</Link>
                                            </li>
                                        </ul>

                                        <h2 className="font-size-2 pb-2 border-bottom"><Link onClick={handleLinkClick} to='/myAlbum'>마이페이지</Link></h2>
                                        <ul>
                                            <li className="font-size-2 mx-2">
                                                {isAuthenticated ? (
                                                    <Link className="p-2 px-5"  role="button" onClick={handleLogout}>로그아웃</Link>
                                                ) : (
                                                    <Link id="sidebarNavToggler"  role="button"
                                                       className="p-2 px-5"
                                                       aria-controls="sidebarContent"
                                                       aria-haspopup="true"
                                                       aria-expanded="false"
                                                       data-unfold-event="click"
                                                       data-unfold-hide-on-scroll="false"
                                                       data-unfold-target="#sidebarContent"
                                                       data-unfold-type="css-animation"
                                                       data-unfold-overlay='{
                                "className": "u-sidebar-bg-overlay",
                                "background": "rgba(0, 0, 0, .7)",
                                "animationSpeed": 500
                            }'
                                                       data-unfold-animation-in="fadeInRight"
                                                       data-unfold-animation-out="fadeOutRight"
                                                       data-unfold-duration="500">
                                                        로그인
                                                    </Link>
                                                )}
                                            </li>
                                            <li className="font-size-2 mx-2"><Link className="p-2 px-5" onClick={handleLinkClick}  to='/myPage' >회원정보</Link>
                                            </li>
                                            <li className="font-size-2 mx-2"><Link className="p-2 px-5" onClick={handleLinkClick}  to='/basket' >장바구니</Link>
                                            </li>
                                            <li className="font-size-2 mx-2"><Link className="p-2 px-5" onClick={handleLinkClick}  to='/myAlbum' >보관함/다운로드</Link>
                                            </li>
                                        </ul>

                                        <h2 className="font-size-2 pb-2 border-bottom"><Link onClick={handleLinkClick} to='/guide'>이용안내</Link></h2>
                                        <ul>
                                            <li className="font-size-2 mx-2"><Link className="p-2 px-5"
                                                                                   onClick={handleLinkClick}  to='/guide'>이용안내</Link>
                                            </li>
                                            <li className="font-size-2 mx-2"><Link className="p-2 px-5"
                                                                                   onClick={handleLinkClick}  to='/faq'>FAQ</Link>
                                            </li>
                                            <li className="font-size-2 mx-2"><Link className="p-2 px-5"
                                                                                   onClick={handleLinkClick}  to='/inquiry'>1:1문의</Link>
                                            </li>
                                        </ul>

                                        <h2 className="font-size-2 pb-2 border-bottom"><Link onClick={handleLinkClick} to='/notice'>정보</Link></h2>
                                        <ul>
                                            <li className="font-size-2 mx-2"><Link className="p-2 px-5" onClick={handleLinkClick} to='/notice'>공지사항</Link>
                                            </li>
                                            <li className="font-size-2 mx-2"><Link className="p-2 px-5" onClick={handleLinkClick} to='/aboutUs'>회사소개</Link>
                                            </li>
                                            <li className="font-size-2 mx-2"><Link className="p-2 px-5" onClick={handleLinkClick} to='/usageTerms'>이용약관</Link>
                                            </li>
                                        </ul>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    )

}

export default Sidebar;