import React from "react";
import { Link } from 'react-router-dom';

const InformationUseHeader = ({ pageType }) => {

    return (
        <ul className="nav justify-content-md-center nav-gray-700 mb-5 flex-nowrap flex-md-wrap overflow-auto overflow-md-visible"
            id="featuredBooks" role="tablist">
            <li className="nav-item mx-5 mb-1 flex-shrink-0 flex-md-shrink-1">
                <Link className={pageType === 'guide' ? "nav-link px-0 active" : "nav-link px-0"} id="guide-tab" data-toggle="tab" href="#guide" role="tab"
                   aria-controls="guide" aria-selected="true" to='/guide' >이용안내
                </Link>
            </li>
            <li className="nav-item mx-5 mb-1 flex-shrink-0 flex-md-shrink-1">
                <Link className={pageType === 'faq' ? "nav-link px-0 active" : "nav-link px-0"} id="faq-tab" data-toggle="tab" href="#faq" role="tab"
                   aria-controls="faq" aria-selected="false" to='/faq'  >FAQ</Link>
            </li>
            <li className="nav-item mx-5 mb-1 flex-shrink-0 flex-md-shrink-1">
                <Link className={pageType === 'inquiry' ? "nav-link px-0 active" : "nav-link px-0"} id="inquiry-tab" data-toggle="tab" href="#inquiry" role="tab"
                   aria-controls="inquiry" aria-selected="false" to='/inquiry'  >1:1문의</Link>
            </li>

        </ul>
    )
}

export default InformationUseHeader;