import React from "react";
import Pagination from "../Pagination";

const SearchList = () => {
    const selectList = [
        {value: 1, label: '추천'},
        {value: 2, label: '등록순'},
        {value: 3, label: '발매순'},
        {value: 4, label: '인기순'},
    ]

    return (
        <>
            <div className="left-sidebar">
                <div className="site-content space-bottom-3 mt-8" id="content">
                    <div className="container">
                        <div className="row">
                            <div id="primary" className="content-area order-2">
                                <div
                                    className="shop-control-bar d-lg-flex justify-content-between align-items-center mb-5 text-center text-md-left">
                                    <div className="shop-control-bar__left mb-4 m-lg-0">
                                        <p className="woocommerce-result-count m-0">총 <span>0</span>개의
                                            앨범</p>
                                    </div>
                                    <div className="shop-control-bar__right d-md-flex align-items-center">
                                        <form className="woocommerce-ordering mb-4 m-md-0" method="get">

                                            <select
                                                className="js-select selectpicker dropdown-select orderby shadow-none outline-none py-2"
                                                name="orderby"
                                                data-style="border-bottom shadow-none outline-none py-2">
                                                {selectList.map((e) => {
                                                    return <option key={e.value} value={e.value}>{e.label}</option>;
                                                })}
                                            </select>
                                        </form>

                                        <ul className="nav nav-tab ml-lg-4 justify-content-center justify-content-md-start ml-md-auto"
                                            id="pills-tab" role="tablist">
                                            <li className="nav-item border">
                                                <a className="nav-link p-0 height-38 width-38 justify-content-center d-flex align-items-center active"
                                                   id="pills-one-example1-tab" data-toggle="pill"
                                                   href="#pills-one-example1" role="tab"
                                                   aria-controls="pills-one-example1" aria-selected="true">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         xlinkHref="http://www.w3.org/1999/xlink" width="17px"
                                                         height="17px">
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M-0.000,0.000 L3.000,0.000 L3.000,3.000 L-0.000,3.000 L-0.000,0.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M7.000,0.000 L10.000,0.000 L10.000,3.000 L7.000,3.000 L7.000,0.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M14.000,0.000 L17.000,0.000 L17.000,3.000 L14.000,3.000 L14.000,0.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M-0.000,7.000 L3.000,7.000 L3.000,10.000 L-0.000,10.000 L-0.000,7.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M7.000,7.000 L10.000,7.000 L10.000,10.000 L7.000,10.000 L7.000,7.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M14.000,7.000 L17.000,7.000 L17.000,10.000 L14.000,10.000 L14.000,7.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M-0.000,14.000 L3.000,14.000 L3.000,17.000 L-0.000,17.000 L-0.000,14.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M7.000,14.000 L10.000,14.000 L10.000,17.000 L7.000,17.000 L7.000,14.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M14.000,14.000 L17.000,14.000 L17.000,17.000 L14.000,17.000 L14.000,14.000 Z"/>
                                                    </svg>
                                                </a>
                                            </li>
                                            <li className="nav-item border">
                                                <a className="nav-link p-0 height-38 width-38 justify-content-center d-flex align-items-center"
                                                   id="pills-two-example1-tab" data-toggle="pill"
                                                   href="#pills-two-example1" role="tab"
                                                   aria-controls="pills-two-example1" aria-selected="false">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         xlinkHref="http://www.w3.org/1999/xlink" width="23px"
                                                         height="17px">
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M-0.000,0.000 L3.000,0.000 L3.000,3.000 L-0.000,3.000 L-0.000,0.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M7.000,0.000 L23.000,0.000 L23.000,3.000 L7.000,3.000 L7.000,0.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M-0.000,7.000 L3.000,7.000 L3.000,10.000 L-0.000,10.000 L-0.000,7.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M7.000,7.000 L23.000,7.000 L23.000,10.000 L7.000,10.000 L7.000,7.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M-0.000,14.000 L3.000,14.000 L3.000,17.000 L-0.000,17.000 L-0.000,14.000 Z"/>
                                                        <path fillRule="evenodd" fill="rgb(25, 17, 11)"
                                                              d="M7.000,14.000 L23.000,14.000 L23.000,17.000 L7.000,17.000 L7.000,14.000 Z"/>
                                                    </svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-one-example1" role="tabpanel"
                                         aria-labelledby="pills-one-example1-tab">

                                        <ul className="products list-unstyled row no-gutters row-cols-2 row-cols-lg-3 row-cols-wd-4 border-top border-left mb-6">
                                            <li className="product col">
                                                <div className="product__inner overflow-hidden p-3 p-md-4d875">
                                                    <div
                                                        className="woocommerce-LoopProduct-link woocommerce-loop-product__link d-block position-relative">
                                                        <div className="woocommerce-loop-product__thumbnail">
                                                            <a href="#" className="d-block"><img
                                                                width="125" height="125"
                                                                src='https://placehold.it/125x125'
                                                                className="img-fluid d-block mx-auto attachment-shop_catalog size-shop_catalog wp-post-image img-fluid"
                                                                alt="image-description"/></a>
                                                        </div>
                                                        <div
                                                            className="woocommerce-loop-product__body product__body pt-3 bg-white">
                                                            <div
                                                                className="text-uppercase font-size-1 mb-1 text-truncate">
                                                                <a
                                                                    href="#">장르</a></div>
                                                            <h2 className="woocommerce-loop-product__title product__title h6 text-lh-md mb-1 text-height-2 crop-text-2 h-dark">
                                                                <a href="#">앨범명</a></h2>
                                                            <div className="font-size-2  mb-1 text-truncate"><a
                                                                href="#"
                                                                className="text-gray-700">아티스트명</a></div>
                                                            <div
                                                                className="price d-flex align-items-center font-weight-medium font-size-3">
                                                    <span className="woocommerce-Price-amount amount"><span
                                                        className="woocommerce-Price-currencySymbol">1000원</span></span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                    <div className="tab-pane fade" id="pills-two-example1" role="tabpanel"
                                         aria-labelledby="pills-two-example1-tab">

                                        <ul className="products list-unstyled mb-6">
                                            <li  className="product product__list">
                                                <div className="product__inner overflow-hidden p-3 p-md-4d875">
                                                    <div
                                                        className="woocommerce-LoopProduct-link woocommerce-loop-product__link row position-relative">
                                                        <div
                                                            className="col-md-auto woocommerce-loop-product__thumbnail mb-3 mb-md-0">
                                                            <a href="" className="d-block"><img
                                                                width="125" height="125"
                                                                src="https://placehold.it/125x125"
                                                                className="img-fluid d-block mx-auto attachment-shop_catalog size-shop_catalog wp-post-image img-fluid"
                                                                alt="image-description"/></a>
                                                        </div>
                                                        <div
                                                            className="col-md woocommerce-loop-product__body product__body pt-3 bg-white mb-3 mb-md-0">
                                                            <div
                                                                className="text-uppercase font-size-1 mb-1 text-truncate">
                                                                <a
                                                                    href="">장르</a></div>
                                                            <h2 className="woocommerce-loop-product__title product__title h6 text-lh-md mb-1 crop-text-2 h-dark">
                                                                <a href="" tabIndex="0">앨범명</a></h2>
                                                            <div className="font-size-2  mb-2 text-truncate"><a
                                                                href=""
                                                                className="text-gray-700">아티스트명</a></div>

                                                            <div
                                                                className="price d-flex align-items-center font-weight-medium font-size-3">
                                                    <span className="woocommerce-Price-amount amount"><span
                                                        className="woocommerce-Price-currencySymbol">1000</span>원</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                        </ul>

                                    </div>
                                </div>

                                <Pagination
                                    // activePage={pageInfo.activePage}
                                    // items={pageInfo.itemsCountPerPage} // 페이지당 항목수
                                    // itemsCount={pageInfo.itemCount}
                                    // pageDisplay={pageInfo.pageDisplay}
                                    // onPageChange={pageInfo.onPageChange}
                                />

                            </div>
                            <div id="secondary" className="sidebar widget-area order-1" role="complementary">
                                <div id="widgetAccordion">
                                    <div id="woocommerce_product_categories-2"
                                         className="widget p-4d875 border woocommerce widget_product_categories">
                                        <div id="widgetHeadingOne" className="widget-head">
                                            <a className="d-flex align-items-center justify-content-between text-dark"
                                               href="#"
                                               data-toggle="collapse"
                                               data-target="#widgetCollapseOne"
                                               aria-expanded="true"
                                               aria-controls="widgetCollapseOne">

                                                <h3 className="widget-title mb-0 font-weight-medium font-size-3">장르</h3>

                                                <svg className="mins" xmlns="http://www.w3.org/2000/svg"
                                                     xlinkHref="http://www.w3.org/1999/xlink" width="15px" height="2px">
                                                    <path fillRule="evenodd" fill="rgb(22, 22, 25)"
                                                          d="M0.000,-0.000 L15.000,-0.000 L15.000,2.000 L0.000,2.000 L0.000,-0.000 Z"/>
                                                </svg>

                                                <svg className="plus" xmlns="http://www.w3.org/2000/svg"
                                                     xlinkHref="http://www.w3.org/1999/xlink" width="15px"
                                                     height="15px">
                                                    <path fillRule="evenodd" fill="rgb(22, 22, 25)"
                                                          d="M15.000,8.000 L9.000,8.000 L9.000,15.000 L7.000,15.000 L7.000,8.000 L0.000,8.000 L0.000,6.000 L7.000,6.000 L7.000,-0.000 L9.000,-0.000 L9.000,6.000 L15.000,6.000 L15.000,8.000 Z"/>
                                                </svg>
                                            </a>
                                        </div>

                                        <div id="widgetCollapseOne" className="mt-3 widget-content collapse show"
                                             aria-labelledby="widgetHeadingOne"
                                             data-parent="#widgetAccordion">
                                            {/* 장르 종류 */}
                                            <ul className="product-categories">
                                                <li className="cat-item cat-item-9 cat-parent">
                                                    <a href="../shop/v3.html">Clothing</a>
                                                    <ul className="children">
                                                    <li className="cat-item cat-item-121"><a
                                                            href="#/clothing/bags/">Bags</a></li>
                                                        <li className="cat-item cat-item-44"><a
                                                            href="#/clothing/blouses/">Blouses</a></li>
                                                        <li className="cat-item cat-item-41"><a
                                                            href="#/clothing/dresses/">Dresses</a></li>
                                                        <li className="cat-item cat-item-56"><a
                                                            href="#/clothing/footwear/">Footwear</a></li>
                                                        <li className="cat-item cat-item-54"><a
                                                            href="#/clothing/hats/">Hats</a></li>
                                                        <li className="cat-item cat-item-10"><a
                                                            href="#/clothing/hoodies/">Hoodies</a></li>
                                                        <li className="cat-item cat-item-55"><a
                                                            href="#/clothing/shirts/">Shirts</a></li>
                                                        <li className="cat-item cat-item-47"><a
                                                            href="#/clothing/skirts/">Skirts</a></li>
                                                        <li className="cat-item cat-item-14"><a
                                                            href="#/clothing/t-shirts/">T-shirts</a></li>
                                                        <li className="cat-item cat-item-49"><a
                                                            href="#/clothing/trousers/">Trousers</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div id="Authors" className="widget widget_search widget_author p-4d875 border">
                                        <div id="widgetHeading21" className="widget-head">
                                            <a className="d-flex align-items-center justify-content-between text-dark"
                                               href="#"
                                               data-toggle="collapse"
                                               data-target="#widgetCollapse21"
                                               aria-expanded="true"
                                               aria-controls="widgetCollapse21">

                                                <h3 className="widget-title mb-0 font-weight-medium font-size-3">아티스트</h3>

                                                <svg className="mins" xmlns="http://www.w3.org/2000/svg"
                                                     xlinkHref="http://www.w3.org/1999/xlink" width="15px" height="2px">
                                                    <path fillRule="evenodd" fill="rgb(22, 22, 25)"
                                                          d="M0.000,-0.000 L15.000,-0.000 L15.000,2.000 L0.000,2.000 L0.000,-0.000 Z"/>
                                                </svg>

                                                <svg className="plus" xmlns="http://www.w3.org/2000/svg"
                                                     xlinkHref="http://www.w3.org/1999/xlink" width="15px"
                                                     height="15px">
                                                    <path fillRule="evenodd" fill="rgb(22, 22, 25)"
                                                          d="M15.000,8.000 L9.000,8.000 L9.000,15.000 L7.000,15.000 L7.000,8.000 L0.000,8.000 L0.000,6.000 L7.000,6.000 L7.000,-0.000 L9.000,-0.000 L9.000,6.000 L15.000,6.000 L15.000,8.000 Z"/>
                                                </svg>
                                            </a>
                                        </div>

                                        <div id="widgetCollapse21" className="mt-4 widget-content collapse show"
                                             aria-labelledby="widgetHeading21"
                                             data-parent="#widgetAccordion">
                                            {/* 아티스트 */}
                                            <ul className="product-categories">
                                                <li className="cat-item cat-item-9 cat-parent">
                                                    <a href="../others/authors-single.html">A. J. Finn</a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SearchList;