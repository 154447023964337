import React from 'react';
import { useForm } from "react-hook-form";
import axiosInstanceWithToken from "../../containers/axiosInstanceWithToken";
import * as userService from "../../containers/userService";
const apiUrl = process.env.REACT_APP_APIGATEWAY_ENDPOINT;

const Inquiry = () => {
    const { register, handleSubmit, formState: { errors }  } = useForm();

    const onSubmit =(data) => {
        const formData = new FormData();

        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });

        axiosInstanceWithToken.post(apiUrl + "/common/board/inquiry", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            alert("문의 내용이 전송되었습니다.")
            window.location.href= "/inquiry";
        }).catch((error) => {
            const statusCode = error.response.status ? error.response.status : error.status;
            if(statusCode === 401){

            }else {
                alert("실패 다시 시도해주세요")
            }
        })
    }

    return (
        <div className="col-xl-6 mx-auto pb-10 border-bottom">
            <h6 className="font-weight-medium font-size-10 mb-3 pb-xl-1">1:1 문의</h6>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">

                    <div className="col-sm-12 mb-5">
                        {/*<div className="js-form-message">*/}
                        {/*    <label htmlFor="exampleFormControlInput2">이메일</label>*/}
                        {/*    <input id="exampleFormControlInput2" type="email" className="form-control rounded-0"*/}
                        {/*           name="email" aria-label="jackwayley@gmail.com" required=""*/}
                        {/*           data-msg="Please enter a valid email address." data-error-class="u-has-error"*/}
                        {/*           data-success-class="u-has-success"/>*/}
                        {/*    <span className="font-size-2 text-gray-700"> * 해당 메일 주소로 문의 답변이 전송됩니다. </span>*/}
                        {/*</div>*/}
                    </div>
                    <div className="col-sm-12 mb-5">
                        <div className="js-form-message">
                            <label htmlFor="title">제목</label>
                            <input id="title" type="text" className="form-control rounded-0"
                                   name="title" required
                                   {...register("title")}/>
                        </div>
                    </div>
                    <div className="col-sm-12 mb-5">
                        <div className="js-form-message">
                            <div className="input-group flex-column">
                                <label htmlFor="content">문의내용</label>
                                <textarea id="content"
                                          className="form-control rounded-0 pl-3 font-size-2 placeholder-color-3"
                                          rows="6" cols="77" name="content"
                                          placeholder="문의 내용을 작성해주세요"
                                          required
                                          {...register("content")}></textarea>
                            </div>
                        </div>
                    </div>

                    <div className="col d-flex justify-content-lg-start">
                        <button type="submit"
                                className="btn btn-wide btn-dark text-white rounded-0 transition-3d-hover height-60">
                            문의하기
                        </button>
                    </div>
                    <div className="font-size-2 text-gray-700">* 문의 답변은 이메일로 전송됩니다.</div>
                </div>
            </form>
        </div>
    )

}

export default Inquiry;