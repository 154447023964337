import React from 'react';
import Notice from "../../components/common/Notice";

const NoticePage = ({dataType}) => {
    return(
        <>
            <Notice dataType={dataType} />
        </>
    )
}

export default NoticePage;