import store from '../../../store/store';
import {inicisOverlayClose} from "../../../store/action/BasketAction";
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'


const CloseInicis = ({handleInicisOverlayFlag}) => {
    const navigate = useNavigate()

    useEffect(() => {
        const script = document.createElement("script");
        script.src =
            "https://stgstdpay.inicis.com/stdjs/INIStdPay_close.js";
        document.head.appendChild(script);
        // store.dispatch(inicisOverlayClose());
        handleInicisOverlayFlag(false)

        return ()=> {
            document.head.removeChild(script)
            navigate(-1)
        };
    }, []);

    return(
        <>
        </>

    )
};

export default CloseInicis;