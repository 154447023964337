import React from 'react';
import { Link } from 'react-router-dom';

const PaymentSuccess = () => {

    return(
        <div className="space-2 space-md-3 space-lg-4 space-top-xl-4 space-bottom-xl-4">
            <div className="d-flex flex-column align-items-center">
                <div className="font-weight-medium font-size-15 font-size-xs-25 mb-3">결제가 완료되었습니다.
                </div>
                <h6 className="font-weight-medium mb-2 col-lg-7 text-center text-lh-lg mb-5">구매한 상품은 보관함/다운로드에서 확인 가능합니다. <br/> 아래 링크를 클릭하면 보관함으로 이동됩니다.</h6>
                <Link to={'/myAlbum'}>보관함으로 이동</Link>
            </div>
        </div>
    )
};

export default PaymentSuccess;