import React from 'react';
import {GlobalNavbar, Sidebar} from "./Headers";
import {LoginSidebar} from "../user";

const Header = ({onPageChange}) => {

    return (
        <>
        <header id="site-header" className="site-header__v1">
            <GlobalNavbar onPageChange={onPageChange}/>
        </header>
            <Sidebar onPageChange={onPageChange}/>
            <LoginSidebar onPageChange={onPageChange}/>
        </>
    );
};

export default Header;