import React from "react";
import SearchList from "../../components/category/SearchList";


const SearchPage = ({filter, searchParam, searchData}) => {





    return(
        <>
            <SearchList/>
        </>
    )
}

export default SearchPage;