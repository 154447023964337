// 액션 타입 정의
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// 액션 생성자 정의
export const loginAction = (token) => ({
    type: LOGIN,
    payload: token,
});

export const logoutAction = () => ({
    type: LOGOUT,
});