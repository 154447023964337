import React from "react";
import SpecialList from "../../components/category/SpecialList";
import SpecialDetail from "../../components/category/SpecialDetail";

const SpecialPage = ({pageType}) => {
    return(
        <>
            <SpecialList/>
        </>
    )
}

export default SpecialPage;