export const BOARD_TYPE_REQUEST = 'BOARD_TYPE_REQUEST';
export const BOARD_DATA_SUCCESS = 'BOARD_DATA_SUCCESS';
export const BOARD_DATA_FAIL = 'BOARD_DATA_FAIL';

export const boardTypeRequest = (type) => ({
    type: BOARD_TYPE_REQUEST,
    payload: type,
})

export const boardDataSuccess = (data) => ({
    type: BOARD_DATA_SUCCESS,
    payload: data,
})

export const boardDataFail = (error) => ({
    type: BOARD_DATA_FAIL,
    payload: error,
})