import React, {useState} from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import store from "../../store/store";
import {loginAction} from "../../store/action/AuthAction";
import { Link } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_APIGATEWAY_ENDPOINT;

const LoginInputMobile = () => {
    const { register, handleSubmit, formState: { errors }  } = useForm();
    const [inputBox, setInputBox] = useState(false);

    const onSubmit = async data => {
        const formData = new FormData();
        // Object.keys(data).forEach(key => {
        //     formData.append(key, encrypt(data[key]));
        // });
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });

        axios({
            method: 'post',
            url: apiUrl + '/user/login',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,

        }).then((response) => {
            // alert(response.data + '로그인 성공')
            const { authorization } = response.data;
            localStorage.setItem("authorization", authorization)
            store.dispatch(loginAction(authorization)); // redux 상태 업데이트
            // alert('토큰 발급/저장 완료')
            window.location.replace("/")

        }).catch((error) => {
            console.log(error)
            console.log(error.message)
            // throw error;
            const statusCode = error.response ? error.response.status : error.status;

            switch (statusCode) {
                case 403:
                    alert("아이디/비밀번호를 확인해주세요");
                    break;
                case 400:
                    alert("인증코드를 입력해주세요");
                    setInputBox(true);
                    break;
                case 401:
                    alert("인증코드가 틀렸습니다. 다시 확인해주세요")
                    break;
                default:
                    alert("SERVER ERROR:", error.status)
            }
        });
    }

    const handleInputChange = (event) => {
        console.log(event.target.value);
    };

    return (
        <form className="" onSubmit={handleSubmit(onSubmit)}>
            <div id="login1" data-target-group="idForm1">
                <header className="border-bottom px-4 px-md-6 py-4">
                    <h2 className="font-size-3 mb-0 d-flex align-items-center"><i
                        className="flaticon-user mr-3 font-size-5"></i>로그인</h2>
                </header>

                <div className="p-4 p-md-6">
                    <div className="form-group mb-4">
                        <div className="js-form-message js-focus-state">
                            <label id="signinEmailLabel9" className="form-label"
                                   htmlFor="signinEmail9">이메일</label>
                            <input type="email"
                                   className="form-control rounded-0 height-4 px-4"
                                   name="email" id="signinEmail9"
                                   placeholder="user@pandori.com"
                                   aria-label="user@pandori.com"
                                   aria-describedby="signinEmailLabel9" required
                                   {...register("userEmail")}/>
                        </div>
                    </div>

                    <div className="form-group mb-4">
                        <div className="js-form-message js-focus-state">
                            <label id="signinPasswordLabel9" className="form-label"
                                   htmlFor="signinPassword9">비밀번호</label>
                            <input type="password"
                                   className="form-control rounded-0 height-4 px-4"
                                   name="password" id="signinPassword9" placeholder=""
                                   aria-label="" aria-describedby="signinPasswordLabel9"
                                   required
                                   {...register("userPwd")}/>
                        </div>
                    </div>

                    <>
                        {inputBox && (
                            <div className="form-group mb-4" onChange={handleInputChange}>
                                <div className="js-form-message js-focus-state">
                                    <label id="authLabel" className="form-label"
                                           htmlFor="authCode">인증코드</label>
                                    <input type="text"
                                           className="form-control rounded-0 height-4 px-4"
                                           name="userAuthCode" id="userAuthCode" placeholder=""
                                           aria-label="" aria-describedby="authLabel"
                                           required {...register("userAuthCode", {
                                                pattern : {
                                                    value: /^[0-9]{6}$/,
                                                    message: '6자리 숫자만 입력가능합니다'
                                                }
                                    })}/>
                                    {errors.userAuthCode && <p className="font-size-2 text-primary">{errors.userAuthCode.message}</p>}
                                </div>
                            </div>
                        )}
                    </>

                    <div className="d-flex justify-content-between mb-5 align-items-center">
                        <div className="js-form-message">
                            {/*    <div*/}
                            {/*        className="custom-control custom-checkbox d-flex align-items-center text-muted">*/}
                            {/*        <input type="checkbox" className="custom-control-input"*/}
                            {/*               id="termsCheckbox1" name="termsCheckbox1" required/>*/}
                            {/*        <label className="custom-control-label"*/}
                            {/*               htmlFor="termsCheckbox1">*/}
                            {/*<span className="font-size-2 text-secondary-gray-700">*/}
                            {/*    Remember me*/}
                            {/*</span>*/}
                            {/*        </label>*/}
                            {/*    </div>*/}
                        </div>

                        <Link className="js-animation-link text-dark font-size-2 t-d-u link-muted font-weight-medium"
                           data-target="#forgotPassword1"
                           data-link-group="idForm1"
                           data-animation-in="fadeIn">비밀번호 찾기</Link>
                    </div>

                    <div className="mb-4d75">
                        <button type="submit"
                                className="btn btn-block py-3 rounded-0 btn-dark">로그인
                        </button>
                    </div>

                    <div className="mb-2">
                        <Link
                           className="js-animation-link btn btn-block py-3 rounded-0 btn-outline-dark font-weight-medium"
                           data-target="#signup1"
                           data-link-group="idForm1"
                           data-animation-in="fadeIn">회원가입</Link>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default LoginInputMobile