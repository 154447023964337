import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {BoardThunk} from "../../store/reducer/BoardThunk";

const FAQ = ({pageType, dataType}) => {
    const dispatch = useDispatch();
    const boardState = useSelector((state) => state.board)
    const {data, error} = boardState;

    useEffect(() => {
        dispatch(BoardThunk(dataType));
    }, [dispatch, dataType]);

    return (
        <>
            <div className={pageType === 'faq' ? 'tab-pane fade show active' : "tab-pane fade"} id="faq" role="tabpanel"
                 aria-labelledby="faq-tab">
                <div className="container">
                    <div className="py-4 py-lg-5 py-xl-8">
                        <h6 className="font-weight-medium font-size-7 font-size-xs-25 text-center">FAQ</h6>
                    </div>
                    <div className="col-lg-8 mx-auto">
                        <div className="space-bottom-2 space-bottom-lg-3 faq-accordion">
                            <div className="pb-lg-1">
                                <div className="mb-8 pb-1">
                                    <h6 className="font-weight-medium font-size-4 mb-5"></h6>

                                    <div id="basicsAccordion">
                                        {data === null ? (<div>no data</div>) :
                                            data.map((datalist, index) => (
                                                <>
                                                    <div className="card rounded-0 border-0" key={index}>
                                                        <div
                                                            className="card-header card-collapse p-0 bg-transparent border-bottom-0"
                                                            id={`basicsHeading${index}`}>

                                                            <button type="button"
                                                                    className="collapse-link btn btn-block d-flex align-items-center justify-content-between card-btn py-3 px-0 px-md-4 border rounded-0 shadow-none mt-minus-1"
                                                                    data-toggle="collapse"
                                                                    data-target={`#basicsCollapse${index}`}
                                                                    aria-expanded="false"
                                                                    aria-controls={`basicsCollapse${index}`}>
                                                    <span
                                                        className="mx-1">{datalist.title}</span>

                                                                <svg className="minus"
                                                                     xmlns="http://www.w3.org/2000/svg"
                                                                     xlinkHref="http://www.w3.org/1999/xlink"
                                                                     width="15px"
                                                                     height="2px">
                                                                    <path fillRule="evenodd" fill="rgb(22, 22, 25)"
                                                                          d="M0.000,-0.000 L15.000,-0.000 L15.000,2.000 L0.000,2.000 L0.000,-0.000 Z"/>
                                                                </svg>

                                                                <svg className="plus" xmlns="http://www.w3.org/2000/svg"
                                                                     xlinkHref="http://www.w3.org/1999/xlink"
                                                                     width="15px"
                                                                     height="15px">
                                                                    <path fillRule="evenodd" fill="rgb(22, 22, 25)"
                                                                          d="M15.000,8.000 L9.000,8.000 L9.000,15.000 L7.000,15.000 L7.000,8.000 L0.000,8.000 L0.000,6.000 L7.000,6.000 L7.000,-0.000 L9.000,-0.000 L9.000,6.000 L15.000,6.000 L15.000,8.000 Z"/>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        <div id={`basicsCollapse${index}`} className="collapse"
                                                             aria-labelledby={`basicsHeading${index}`}
                                                             data-parent="#basicsAccordion">
                                                            <div className="card-body p-3 p-md-4">
                                                                <div className="mx-md-1">
                                                                    <p>{datalist.content}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FAQ;