import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import BoardReducer from "./BoardReducer";
import BasketReducer from "./BasketReducer";

// 리듀서 결합
const rootReducer = combineReducers({
    auth: AuthReducer,
    board: BoardReducer,
    basket: BasketReducer
});

export default rootReducer;