import React from "react";
import { Link } from 'react-router-dom';

const Navgationbar = ({pageName }) => {
    return (
        // <div class="mb-5">
            <div className="">
                <div className="page-header border-bottom">
                    <div className="container">
                        <div className="d-md-flex justify-content-between align-items-center py-4">
                            <nav className="woocommerce-breadcrumb font-size-2">
                                <Link to="/" className="h-primary">홈</Link>
                                <span className="breadcrumb-separator mx-1"><i className="fas fa-angle-right"></i></span>
                                <span>
                                    {pageName === '/best' && '베스트'}
                                    {pageName === '/new' && 'NEW'}
                                    {pageName === '/song' && '가요'}
                                    {pageName === '/popzazz' && '팝/재즈'}
                                    {pageName === '/classic' && '클래식'}
                                    {pageName === '/special' && '스페셜'}
                                    {pageName === '/paymentTest' && '결제테스트'}

                                    {pageName === '/search' && '검색'}
                                    {pageName === '/albumDetail' && '상세'}

                                    {pageName === '/myPage' && '회원정보'}
                                    {pageName === '/basket' && '장바구니'}
                                    {pageName === '/myAlbum' && '보관함/다운로드'}
                                    
                                    {pageName === '/guide' && '이용안내'}
                                    {pageName === '/faq' && 'FAQ'}
                                    {pageName === '/inquiry' && '1:1문의'}
                                    {pageName === '/payment/success' && '결제 완료'}
                                </span>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        // </div>
    )
}

export default Navgationbar;