import axios from "axios";
import * as userService from "./userService";
const apiUrl = process.env.REACT_APP_APIGATEWAY_ENDPOINT

const apiEndpoint = process.env.REACT_APP_APIGATEWAY_ENDPOINT

// jwt 토큰 확인 필요한 모든 요청에 사용
const axiosInstanceWithToken = axios.create({
    baseURL: apiEndpoint,
    withCredentials: true,
    headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('authorization'),

        // 추가
        "Access-Control-Allow-Origin": '*',
        'Access-Control-Allow-Credentials':"true",
    }
});

// 요청 인터셉터 (요청인터셉터가 있어야 응답가능)
axiosInstanceWithToken.interceptors.request.use((config) => {
    config.headers = config.headers || {};
    config.headers.Authorization = localStorage.getItem('authorization');
    return config;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

// 응답 인터셉터
axiosInstanceWithToken.interceptors.response.use(response => {
    const newtoken = response.headers['newtoken'];
    if(newtoken !== undefined){
        localStorage.setItem('authorization', newtoken);
    }
    return response.data;
}, error => {
    console.log(error);
    const statusCode = error.response ? error.response.status : 503;
    if(statusCode === 401){ // 비정상 토큰, refresh토큰 X,  401 반환
        alert("로그인이 필요합니다.")
        userService.Logout();
    }
    // return Promise.reject(error.message);
    return Promise.reject(error);
});

export default axiosInstanceWithToken;