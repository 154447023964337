import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {

    return (
        <footer>
            <div className="">
                <div className="border-top border-bottom pb-5 space-bottom-lg-1 space-top-lg-2">
                    <div className="container">
                        <div className="p-4">
                            <ul className="nav">
                                <li className="nav-item pr-5  border-right">
                                    <Link className="h-primary font-size-2" to='/usageTerms'>이용약관</Link>
                                </li>
                                <li className="nav-item pr-5 pl-5 border-right">
                                    <Link className="h-primary font-size-2" to='/privacyPolicy'>개인정보처리방침</Link>
                                </li>
                                <li className="nav-item pr-5 pl-5">
                                    <Link className="h-primary font-size-2" to='/inquiry'>1:1 문의</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="d-md-flex align-items-center mb-5">
                            <div className="p-4">로고이미지 (예정)</div>
                            <div className="mx-md-8">
                                <span className="font-size-2 text-gray-500">
                                    대표이사 | 주소 | 사업자번호 | 통신판매일신고 신고번호 개인정보보호책임자 | 서비스 문의 전화
                                    | 이메일 | 저작권 표시 | 대표이사 | 주소 | 사업자번호 | 통신판매일신고 신고번호 개인정보보호책임자 | 서비스 문의 전화
                                    | 이메일 | 저작권 표시 | 대표이사 | 주소 | 사업자번호 | 통신판매일신고 신고번호 개인정보보호책임자 | 서비스 문의 전화
                                    | 이메일 | 저작권 표시 |
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )

}

export default Footer;