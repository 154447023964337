import React, {useEffect, useState} from "react";
import SidebarMypage from "../../components/myPage/SidebarMypage";
import UserInfo from "../../components/myPage/UserInfo";
import BasketItems from "../../components/myPage/BasketItems"
import MyAlbum from "../../components/myPage/MyAlbum";
import {useSelector} from 'react-redux';
import PasswordAuth from "../../components/myPage/PasswordAuth";
import {CloseInicis} from '../../components/payment/inicis/index';
import PaymentSuccess from "../../components/payment/PaymentSuccess"
import PaymentFail from "../../components/payment/PaymentFail"
import { useParams } from 'react-router-dom';

const MyPage = ({pageType}) => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const [isPwdAuth, setPwdAuth] = useState(false);
    const [userInfo, setUserInfo] = React.useState();
    // const inicisOverlayFlag = useSelector((state) => state.basket.inicisOverlayFlag);
    const [inicisOverlayFlag, setInicisOverlayFlag] = useState(false)
    // const [paymentFailCause, setPaymentFailCause] = useState('')
    const {cause} = useParams();

    const handleInicisOverlayFlag = (value) => {
        setInicisOverlayFlag(value)
    }

    const handleAuthSuccess = (userData) => {
        setPwdAuth(true);
        setUserInfo(userData);
    };


    useEffect(() => {
        if (!isAuthenticated) {
            alert('로그인이 필요합니다')
            window.history.back()
        }
        if(pageType === 'paymentSuccess' || pageType === 'paymentFail') {
            console.log(cause)
        }
    }, [isAuthenticated]);

    return(
        <>
            <div className="container">
                <div className="row">
                    <SidebarMypage pageType={pageType} />
                    <div className="col-md-9">
                        {pageType === 'myPage' && !isPwdAuth && <PasswordAuth pwdSuccess={handleAuthSuccess}/>}
                        {pageType === 'myPage' && isPwdAuth && <UserInfo pageType={pageType} userInfo={userInfo}/>}
                        {pageType === 'basket' && <BasketItems pageType={pageType} inicisOverlayFlag={inicisOverlayFlag} handleInicisOverlayFlag={handleInicisOverlayFlag} />}
                        {pageType === 'close-inicis' && <CloseInicis handleInicisOverlayFlag={handleInicisOverlayFlag} />}
                        {pageType === 'myAlbum' && <MyAlbum pageType={pageType}/>}
                        {pageType === 'paymentSuccess' && <PaymentSuccess />}
                        {pageType === 'paymentFail' && <PaymentFail cause={cause} />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyPage;