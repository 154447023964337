import React, {useEffect, useRef, useState} from "react";
import {AlbumList} from "../../components/category";
import BannerAlbum from "../../components/home/BannerAlbum"
import axios from "axios";

const apiUrl = process.env.REACT_APP_APIGATEWAY_ENDPOINT
const albumCountPerPage = process.env.REACT_APP_PAGINATION_ALBUM_DISPLAY_COUNT

const CategoryPage = ({pageType}) => {
    const didMount = useRef(false);
    const [albumList, setAlbumList] = useState([]);
    const [recommendAlbumList, setRecommendAlbumList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageInfo, setPageInfo] = useState({
        itemsCountPerPage: albumCountPerPage,
        itemCount: 0,
        pageDisplay: 5,
        onPageChange: setPage
    });
    const [sortType, setSortType] = useState(1)
    const changeSort = (sortType) => {
        setSortType(sortType)
    }

    const fetchAllCategoryProducts = () => {
        axios.get(apiUrl + "/product/main/list?category=" + pageType + "&page=" + page + "&sortType=" + sortType + "&albumCountPerPage=" + albumCountPerPage)
            .then((res) => {
                // setBannerList( res.data)
                const categories = res.data.albums;
                categories.forEach(e => {
                    switch (e.category) {
                        case Number.parseInt(pageType):
                            setAlbumList(e.albumList);
                            const pageInfo = {
                                activePage: page,
                                itemsCountPerPage: albumCountPerPage,
                                itemCount: e.albumCount,
                                pageDisplay: 5,
                                onPageChange: setPage
                            }
                            setPageInfo(pageInfo)
                            break;
                        default:
                    }
                })
                setRecommendAlbumList(res.data.recommendAlbums)
            })
    }

    useEffect(() => {
        if(page !== 1) {
            setPage(1)
        } else {
            fetchAllCategoryProducts();
        }
    }, [pageType]);

    useEffect(() => {
        if(didMount.current) {
            fetchAllCategoryProducts();
        }
    }, [page]);

    useEffect(() => {
        if(didMount.current) {
            fetchAllCategoryProducts();
        } else {
            didMount.current = true;
        }
    }, [sortType]);

    return(
        <>
            <BannerAlbum albumList={recommendAlbumList}/>
            <AlbumList albumList={albumList} pageInfo={pageInfo} changeSort={changeSort} />
        </>
    )
}

export default CategoryPage;