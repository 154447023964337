import React from "react";
import BoardGeneric from "../../components/common/BoardGeneric";

// 이용약관, 개인정보처리방침, 회사소개
const SingleBoardPage = ({dataType}) => {
    return(
        <>
            <BoardGeneric dataType={dataType} />
        </>
    )
}

export default SingleBoardPage;