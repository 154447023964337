import React from "react";
import { Link } from 'react-router-dom';
import SpecialDetail from "./SpecialDetail";

const SpecialList = ({dataType}) => {
    return(
        <>
            <div className="mb-5 mb-lg-8">
                <div className="mb-5">
                    <div className="container">
                        {/* 상단 메뉴바 */}
                        <ul className="nav justify-content-md-center nav-gray-700 mb-5 flex-nowrap flex-md-wrap overflow-auto overflow-md-visible"
                            id="featuredBooks" role="tablist">
                            <li className="nav-item mx-5 mb-1 flex-shrink-0 flex-md-shrink-1">
                                <Link className="nav-link px-0 active" id="featured-tab" data-toggle="tab" to="#featured"
                                   role="tab" aria-controls="featured" aria-selected="true">All
                                </Link>
                            </li>
                            <li className="nav-item mx-5 mb-1 flex-shrink-0 flex-md-shrink-1">
                                <Link className="nav-link px-0" id="onsale-tab" data-toggle="tab" to="#onsale" role="tab"
                                   aria-controls="onsale" aria-selected="false">특집</Link>
                            </li>
                            <li className="nav-item mx-5 mb-1 flex-shrink-0 flex-md-shrink-1">
                                <Link className="nav-link px-0" id="mostviewed-tab" data-toggle="tab" to="#mostviewed"
                                   role="tab" aria-controls="mostviewed" aria-selected="false">이벤트</Link>
                            </li>
                        </ul>


                        <div className="tab-content">
                            {/* ALL */}
                            <div className="tab-pane fade show active" id="featured" role="tabpanel"
                                 aria-labelledby="featured-tab">
                                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                                    <div className="col">
                                        <div className="mb-5">
                                            <Link className="d-block mb-3" to="">
                                                <img className="img-fluid" src="https://placehold.it/445x300"
                                                     alt="Image-Description"/>
                                            </Link>
                                            <div className="mb-2">
                                                <Link className="font-size-2" to="">특집/이벤트 카테고리 표시</Link>
                                            </div>
                                            <h6 className="font-size-26 crop-text-2 font-weight-medium text-lh-1dot4">
                                                <Link to="" >제목</Link>
                                            </h6>
                                            <p className="font-size-2 text-secondary-gray-700">글내용 첫째줄 미리보기로 넣기 </p>
                                            <div className="font-size-2 text-secondary-gray-700">
                                                <span>작성일</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* 특집 */}
                            <div className="tab-pane fade" id="onsale" role="tabpanel" aria-labelledby="onsale-tab">
                                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                                    <div className="col">
                                        <div className="mb-5">
                                            <Link className="d-block mb-3" to="">
                                                <img className="img-fluid" src="https://placehold.it/445x300"
                                                     alt="Image-Description"/>
                                            </Link>
                                            <div className="mb-2">
                                                <Link className="font-size-2" to="">Romance</Link>
                                            </div>
                                            <h6 className="font-size-26 crop-text-2 font-weight-medium text-lh-1dot4">
                                                <Link href="">Books with Horrible Dating Advice</Link>
                                            </h6>
                                            <p className="font-size-2 text-secondary-gray-700">It’s nice to win awards.
                                                Last night, the Ueno team in Reykjavík came home from the Icelandic Web
                                                Awards.</p>
                                            <div className="font-size-2 text-secondary-gray-700">
                                                <span>10 November, 2020</span>
                                                <span className="ml-3">0 comments</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* 이벤트 */}
                            <div className="tab-pane fade" id="mostviewed" role="tabpanel"
                                 aria-labelledby="mostviewed-tab">
                                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                                    <div className="col">
                                        <div className="mb-5">
                                            <Link className="d-block mb-3" to="">
                                                <img className="img-fluid" src="https://placehold.it/445x300"
                                                     alt="Image-Description"/>
                                            </Link>
                                            <div className="mb-2">
                                                <Link className="font-size-2" to="">Romance</Link>
                                            </div>
                                            <h6 className="font-size-26 crop-text-2 font-weight-medium text-lh-1dot4">
                                                <Link to="">Books with Horrible Dating Advice</Link>
                                            </h6>
                                            <p className="font-size-2 text-secondary-gray-700">It’s nice to win awards.
                                                Last night, the Ueno team in Reykjavík came home from the Icelandic Web
                                                Awards.</p>
                                            <div className="font-size-2 text-secondary-gray-700">
                                                <span>10 November, 2020</span>
                                                <span className="ml-3">0 comments</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            {/*    페이지 네이션 추가하기 */}
            </div>

        </>
    )
}

export default SpecialList;