import React, {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom'
import Slider from "react-slick"

const frontEndUrl = process.env.REACT_APP_FRONTEND

const BannerTop = ({staticBanner, rollingBannerList}) => {
    const sliderRef = React.useRef(null)
    let navigate = useNavigate();
    useEffect(() => {
    }, []);
    const [slideIndex, setSlideIndex] = useState(0);
    const settings = {
        // autoplay: true,
        infinite: true,
        // slidesToShow: 3,
        // slidesToScroll: 1,
        arrows: false,
        dots: true,
        dotsClass: 'js-pagination text-center u-slick__pagination position-absolute right-0 left-0 mb-n8 mb-lg-4 bottom-0',
        beforeChange: (prev, next) => {
            setSlideIndex(next);
        },
        appendDots: dots => {
            return (
                <ul>
                    {dots.map((item, index) => {
                        return (
                            <li className={index === slideIndex ? "slick-active" : ""}
                                key={index}>{item.props.children}</li>
                        );
                    })}
                </ul>
            )
        },
        customPaging: index => {
            return (
                <span></span>
            )
        }
    };

    function linkClickHandler(url, e) {
        if(url.includes(frontEndUrl)) {
            navigate(url.split(frontEndUrl)[1])
        } else {
            window.open(url)
        }
        e.preventDefault()
    }

    return (
        <div className="tab-pane py-4 show active" id="pills-result-4" role="tabpanel"
             aria-labelledby="pills-html-tab-4">
            <div className="container">
                <div className="row row-cols-lg-2">
                    {staticBanner && <div className="col-1">
                        <div className="bg-gray-200 p-5 mb-5 min-height-350"
                             style={{backgroundImage: `url(${staticBanner.bannerImageUrl})`, backgroundSize: 'cover'}}>
                            <div className="mb-2">
                                <span className="font-weight-medium h6 text-gray-400">고정 배너</span>
                            </div>
                            <h6 className="font-weight-bold font-size-7 text-secondary-black-100">고정 배너</h6>
                            <div className="mt-3">
                                <Link
                                    onClick={(e) => linkClickHandler(staticBanner.bannerLinkUrl, e)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn-lg btn-dark btn-wide rounded-0 hero__btn mt-4"
                                    data-scs-animation-in="fadeInLeft"
                                    data-scs-animation-delay="400">
                                    See More</Link>
                            </div>
                        </div>
                    </div>}
                    <div className="col-2">
                        <Slider ref={sliderRef}
                                {...settings}
                                className="js-slick-carousel u-slick"
                                data-pagi-classes="text-center u-slick__pagination position-absolute right-0 left-0 mb-n8 mb-lg-4 bottom-0">
                            {rollingBannerList.map((banner) => {
                                if (banner) {
                                    return <div key={banner.bannerType}>
                                        <div className="js-slide bg-gray-200 p-5 min-height-350"
                                             style={{
                                                 backgroundImage: `url(${banner.bannerImageUrl})`,
                                                 backgroundSize: 'cover'
                                             }}>
                                            <div className="mb-2">
                                                <span className="font-weight-medium h6 text-gray-400">롤링 배너</span>
                                            </div>
                                            <h6 className="font-weight-bold font-size-7">롤링 배너 #{banner.bannerType}</h6>
                                            <div className="media-body mr-wd-4 align-self-center mb-4 mb-md-0">
                                                {/*<p*/}
                                                {/*    className="hero__pretitle text-uppercase font-weight-bold text-gray-400 mb-2"*/}
                                                {/*    data-scs-animation-in="fadeInUp" data-scs-animation-delay="200">The*/}
                                                {/*    Bookworm Editors'</p>*/}
                                                {/*<h2 className="hero__title font-size-14 mb-4"*/}
                                                {/*    data-scs-animation-in="fadeInUp"*/}
                                                {/*    data-scs-animation-delay="300">*/}
                                                {/*    <span className="hero__title-line-1 font-weight-regular d-block">Featured Books of the</span><span*/}
                                                {/*    className="hero__title-line-2 font-weight-bold d-block">February</span>*/}
                                                {/*</h2>*/}
                                                <div className="mt-3">
                                                    <Link onClick={(e) => linkClickHandler(banner.bannerLinkUrl, e)} target="_blank"
                                                          rel="noopener noreferrer"
                                                          className="btn-lg btn-dark btn-wide rounded-0 hero__btn"
                                                          data-scs-animation-in="fadeInLeft"
                                                          data-scs-animation-delay="400">See
                                                        More</Link>
                                                </div>
                                            </div>
                                            {/*<a href={banner.bannerUrlLink} target="_blank" rel="noopener noreferrer"*/}
                                            {/*   className="btn-lg btn-punch btn-wide rounded-0 hero__btn"*/}
                                            {/*   data-scs-animation-in="fadeInLeft" data-scs-animation-delay="400">See*/}
                                            {/*    More</a>*/}
                                        </div>
                                    </div>
                                }
                            })}
                        </Slider>
                    </div>
                </div>

                {/*<Slider*/}
                {/*    ref={sliderRef}*/}
                {/*    {...settings}*/}
                {/*    // beforeChange={handleBeforeChange}*/}
                {/*    // afterChange={handleAfterChange}*/}
                {/*    className="js-slick-carousel u-slick"*/}
                {/*     data-pagi-classes="text-center u-slick__pagination position-absolute right-0 left-0 mb-n8 mb-lg-4 bottom-0">*/}
                {/*    {rollingBannerList.map((banner) => {*/}
                {/*        return <div className="js-slide" key={banner.bannerType}>*/}
                {/*            <div className="hero row min-height-450 align-items-center">*/}
                {/*                <div className="col-lg-7 col-wd-6 mb-4 mb-lg-0">*/}
                {/*                    <div className="media-body mr-wd-4 align-self-center mb-4 mb-md-0">*/}
                {/*                        <p className="hero__pretitle text-uppercase font-weight-bold text-gray-400 mb-2"*/}
                {/*                           data-scs-animation-in="fadeInUp"*/}
                {/*                           data-scs-animation-delay="200">The Bookworm Editors'</p>*/}
                {/*                        <h2 className="hero__title font-size-14 mb-4"*/}
                {/*                            data-scs-animation-in="fadeInUp"*/}
                {/*                            data-scs-animation-delay="300">*/}
                {/*                            <span className="hero__title-line-1 font-weight-regular d-block">Featured Books of the</span>*/}
                {/*                            <span*/}
                {/*                                className="hero__title-line-2 font-weight-bold d-block">February</span>*/}
                {/*                        </h2>*/}
                {/*                        <a href="www.naver.com" target="_blank" rel="noopener noreferrer"*/}
                {/*                           className="btn btn-dark btn-wide rounded-0 hero__btn"*/}
                {/*                           data-scs-animation-in="fadeInLeft"*/}
                {/*                           data-scs-animation-delay="400">See More</a>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div className="col-lg-5 col-wd-6"*/}
                {/*                     data-scs-animation-in="fadeInRight"*/}
                {/*                     data-scs-animation-delay="500">*/}
                {/*                    <img className="img-fluid" src="https://placehold.it/400x400"*/}
                {/*                         alt="image-description"/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>;*/}


                {/*    })}*/}


                {/*</Slider>*/}
            </div>
        </div>
    )

}

export default BannerTop;