import React, {useEffect, useState} from "react";
import {Guide, InformationUseHeader, FAQ, Inquiry, InquiryBoard} from "../../components/common";

const InformationUsePage = ({pageType }) => {

    return(
        <>
            <div className="container">
                <InformationUseHeader pageType={pageType}/>
                <div className="tab-content">
                    {pageType === 'guide' && <Guide pageType={pageType} dataType={3}/>}
                    {pageType === 'faq' && <FAQ pageType={pageType} dataType={5}/>}
                    <div className={pageType === 'inquiry' || pageType === 'inquirySend' ? 'tab-pane fade show active' : "tab-pane fade"} id="inquiry" role="tabpanel" aria-labelledby="inquiry-tab">
                        {pageType === 'inquiry' && <> <InquiryBoard dataType={6} /></>}
                        {pageType === 'inquirySend' && <> <Inquiry /></>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default InformationUsePage