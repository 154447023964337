import axios from 'axios';
import { boardTypeRequest, boardDataSuccess,  boardDataFail} from '../action/BoardAction';
const apiUrl = process.env.REACT_APP_APIGATEWAY_ENDPOINT

export const BoardThunk = (boardType) => {
    return async (dispatch) => {
        dispatch(boardTypeRequest(boardType));
        try {
            const response = await axios.get(apiUrl + '/common/board/genericData', { params: { type: boardType }});
            dispatch(boardDataSuccess(response.data));
        } catch (error) {
            dispatch(boardDataFail(error.message));
        }
    };
};

export const InquiryThunk = (boardType) => {
    return async (dispatch) => {
        dispatch(boardTypeRequest(boardType));
        try {
            const response = await axios.get(apiUrl + '/common/board/inquiryList',
                { params: { type: boardType }
                    ,
                    headers: {
                        authorization : localStorage.getItem('authorization')
                    }
                });
            dispatch(boardDataSuccess(response.data));
        } catch (error) {
            const statusCode = error.response.status;
            if(statusCode == 401){
                dispatch(boardDataFail(statusCode));
            }else{
                dispatch(boardDataFail(error.message));
            }

        }
    };
};