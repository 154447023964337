import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {BoardThunk} from "../../store/reducer/BoardThunk";
import Pagination from "../Pagination";
import { Link } from 'react-router-dom';

const Notice = ({dataType}) => {

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }

    const dispatch = useDispatch();
    const boardState = useSelector((state) => state.board)
    const { data, error } = boardState;

    // 페이지네이션
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 상태
    const itemsPerPage = 5; // 페이지당 항목 수 설정


    useEffect(() => {
        dispatch(BoardThunk(dataType));
    }, [dispatch, dataType]);


    // 페이지 계산
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    // 페이지 변경 핸들러
    const handlePageChange = (pageNumber) => {
        console.log("Page changed to:", pageNumber);
        setCurrentPage(pageNumber)
    };

    if (error) return <p>Error: {error}</p>;

    return(
        <>

            <div className="row mb-lg-5 mb-xl-0">
                <div className="col-lg-8 col-xl-9 mx-auto">

                    <h6 className="font-weight-medium font-size-7 font-size-xs-25 text-center mt-8 mb-8">공지사항</h6>

                    <div className="d-md-flex align-items-center p-3 bg-punch-light" >
                        <div className="mr-md-2 col-xl-8">
                            <span className="font-size-2 text-secondary-black-200">제목</span>
                        </div>
                        <div className="font-size-2 text-secondary-black-200 mr-md-5 col-xl-2">
                            <span>작성일</span>
                        </div>
                        <div className="mr-md-5">
                            <span className="font-size-2 text-secondary-black-200" >작성자</span>
                        </div>
                    </div>

                    {currentItems.map((datalist, index) => (

                        <div className="d-md-flex align-items-center p-3 border-bottom" key={index}>
                            <div className="mr-md-2 col-xl-8">
                                <Link className="font-size-2 text-secondary-gray-700" to='/boardDetail' state={{data : datalist}}>{datalist.title}</Link>
                            </div>
                            <div className="font-size-2 text-secondary-gray-700 mr-md-5 col-xl-2">
                                <span>{formatDate(datalist.createdAt)}</span>
                            </div>
                            <div className="mr-md-5">
                                <span className="font-size-2 text-primary" >{datalist.createdBy}</span>
                            </div>
                        </div>

                    ))}

                </div>
            </div>

            <nav aria-label="Page navigation example" className="mt-8 mb-8">
                <Pagination
                    activePage={currentPage}
                    items={itemsPerPage} // 페이지당 항목수
                    itemsCount={data.length}
                    pageDisplay={5}
                    onPageChange={handlePageChange}
                />
            </nav>

        </>
    )
}

export default Notice;