import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {BoardThunk} from "../../store/reducer/BoardThunk";

const Guide = ({pageType, dataType}) => {

    const dispatch = useDispatch();
    const boardState = useSelector((state) => state.board)
    const { data, error } = boardState;

    useEffect(() => {
        dispatch(BoardThunk(dataType));
    }, [dispatch, dataType]);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }


    return(
        <>
            <div className={pageType === 'guide' ? 'tab-pane fade show active' : "tab-pane fade"} id="guide"
                 role="tabpanel" aria-labelledby="guide-tab">

                <div className="bookworm-single-post mb-5 mb-lg-6 pb-xl-1">
                    <div className="container">
                        <div>
                            <img className="img-fluid" src="https://placehold.it/1400x650" alt="Image-Description"/>

                            <div className="max-width-940 mx-auto bg-white position-relative">
                                <div className=" mt-n10 mt-md-n13 pt-5 pt-lg-7 px-3 px-md-5 pl-xl-10 pr-xl-8">
                                    <div className="ml-xl-4">
                                        {data === null ? (<div>no data</div>) :
                                            data.map((datalist, index) => (

                                                    <>
                                                        <div className="mb-5 mb-lg-7" key={index}>
                                                            {/*<div className="mb-2">*/}
                                                            {/*    <span className="font-size-2 text-primary">Romance</span>*/}
                                                            {/*</div>*/}
                                                            <h6 className="font-size-10 crop-text-2 font-weight-medium text-lh-1dot4">
                                                                {datalist.title}
                                                            </h6>
                                                            <div className="font-size-2 text-secondary-gray-700">
                                                                <span>{formatDate(datalist.createdAt)}</span>
                                                            </div>
                                                        </div>
                                                        <p>{datalist.content}</p>
                                                    </>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Guide;