import React, {useState} from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_APIGATEWAY_ENDPOINT;

const ForgotPasswordMobile = () => {
    const {register, handleSubmit} = useForm();
    const [errorMsg, setErrorMsg] = useState(false) ;
    const [successMsg, setSuccessMsg] = useState(false);

    const handleInputChange = (e) => {
        setErrorMsg(false)
    }

    const onSubmit = data => {
        const formData = new FormData();

        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });

        axios({
            method: 'put',
            url: apiUrl + '/user/password',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true
        }).then((response) => {
            console.log(response.status)
            console.log(response.data)
            setSuccessMsg(true);

        }).catch((error) => {
            const statusCode = error.response ? error.response.status : error.status;

            switch (statusCode){
                case 403 :
                    setErrorMsg(true);
                    break;
                case 500 :
                    alert('인증 실패. 다시 시도해주세요.')
                    break;
                default:
                    break;
            }
            console.log('error')
        })

    }

    return (
        <form className="" onSubmit={handleSubmit(onSubmit)}>
            <div id="forgotPassword1" style={{display: 'none', opacity: '0'}}
                 data-target-group="idForm1">
                <header className="border-bottom px-4 px-md-6 py-4">
                    <h2 className="font-size-3 mb-0 d-flex align-items-center"><i
                        className="flaticon-question mr-3 font-size-5"></i>비밀번호 찾기
                    </h2>
                </header>

                <div className="p-4 p-md-6">
                    <div className="form-group mb-4">
                        <div className="js-form-message js-focus-state">
                            <label id="signinEmailLabel33" className="form-label"
                                   htmlFor="signinEmail33">이메일</label>
                            <input type="email"
                                   className="form-control rounded-0 height-4 px-4"
                                   name="email" id="signinEmail33"
                                   placeholder="user@pandori.com"
                                   aria-label="user@pandori.com"
                                   aria-describedby="signinEmailLabel33" required
                                   {...register("userEmail")}
                                   onChange={handleInputChange} />
                        </div>
                        {errorMsg &&
                            (<div>
                                <font className="font-size-2" color={'red'}>이메일 정보가 존재하지 않습니다. 다시 입력해주세요</font>
                            </div>)}
                        {successMsg &&
                            (<div>
                                <font className="font-size-2" color={'green'}>임시 비밀번호를 이메일로 전송했습니다.</font>
                            </div>)}
                    </div>

                    <div className="mb-3">
                        <button type="submit"
                                className="btn btn-block py-3 rounded-0 btn-dark">다음
                        </button>
                    </div>

                    <div className="text-center mb-4">
                        <span className="small text-muted">비밀번호가 기억났다면? </span>
                        <Link className="js-animation-link small"
                           data-target="#login1"
                           data-link-group="idForm1"
                           data-animation-in="fadeIn">로그인
                        </Link>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default ForgotPasswordMobile