import {UPDATE} from "../action/BasketAction";

const initialState = {
    basketUpdateCount: 0
};

const BasketReducer = (state = initialState, action) => {
    if(action.type === UPDATE){
        return {
            ...state,
            basketUpdateCount: state.basketUpdateCount + 1,
        }
    } else {
        return state
    }

}

export default BasketReducer