import React from "react";
import { useForm } from "react-hook-form";
import * as userService from "../../containers/userService";
import { Link } from 'react-router-dom';

const JoinInput = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const onSubmit = data => {
        userService.Join(data);
    }

    const userPwd = watch('userPwd');

    return (
        <form className="" onSubmit={handleSubmit(onSubmit)}>
            <div id="signup" style={{display: 'none', opacity: '0'}}
                 data-target-group="idForm">
                <header className="border-bottom px-4 px-md-6 py-4">
                    <h2 className="font-size-3 mb-0 d-flex align-items-center"><i
                        className="flaticon-resume mr-3 font-size-5"></i>회원가입</h2>
                </header>

                <div className="p-4 p-md-6">
                    <div className="form-group mb-4">
                        <div className="js-form-message js-focus-state">
                            <label id="signinEmailLabel1" className="form-label"
                                   htmlFor="signinEmail1">이메일</label>
                            <input type="email"
                                   className="form-control rounded-0 height-4 px-4"
                                   name="email" id="signinEmail1"
                                   placeholder="user@pandori.com"
                                   aria-label="user@pandori.com"
                                   aria-describedby="signinEmailLabel1" required
                                   {...register("userEmail")}/>
                        </div>
                    </div>

                    <div className="form-group mb-4">
                        <div className="js-form-message js-focus-state">
                            <label id="signinPasswordLabel1" className="form-label"
                                   htmlFor="signinPassword1">비밀번호</label>
                            <input type="password"
                                   className="form-control rounded-0 height-4 px-4"
                                   name="password" id="signinPassword1" placeholder=""
                                   aria-label="" aria-describedby="signinPasswordLabel1"
                                   required
                                   {...register("userPwd", {
                                       pattern:{
                                           value: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,16}$/,
                                           message: "8~16자의 영문 대/소문자, 숫자, 특수문자를 사용해 주세요."
                                       }
                                   })}/>
                            {errors.userPwd &&
                                <p className="font-size-2 text-primary">{errors.userPwd.message}</p>}
                        </div>
                    </div>

                    <div className="form-group mb-4">
                        <div className="js-form-message js-focus-state">
                            <label id="signupConfirmPasswordLabel" className="form-label"
                                   htmlFor="signupConfirmPassword">비밀번호 재입력</label>
                            <input type="password"
                                   className="form-control rounded-0 height-4 px-4"
                                   name="confirmPassword" id="signupConfirmPassword"
                                   placeholder="" aria-label=""
                                   aria-describedby="signupConfirmPasswordLabel" required
                                   {...register("confirmPwd", {
                                       validate: (value) => value === userPwd || '비밀번호가 일치하지 않습니다'
                                   })} />
                            {errors.confirmPwd && <p className="font-size-2 text-primary">{errors.confirmPwd.message}</p>}
                        </div>
                    </div>

                    <div className="mb-3">
                        <button type="submit"
                                className="btn btn-block py-3 rounded-0 btn-dark">회원가입
                        </button>
                    </div>

                    <div className="text-center mb-4">
                        <span className="small text-muted">이미 계정이 있으신가요? </span>
                        <Link className="js-animation-link small"
                           data-target="#login"
                           data-link-group="idForm"
                           data-animation-in="fadeIn">로그인
                        </Link>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default JoinInput