import React, {useEffect} from 'react';
import {useLocation, redirect } from "react-router-dom";
import axios from "axios";
const apiUrl = process.env.REACT_APP_APIGATEWAY_ENDPOINT;

const Auth = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const data = queryParams.get("data");

    useEffect(() => {
        axios.post( apiUrl + '/user/auth',  {paramData: data})
        .then((result) => {
            if(result.data === true){
                alert("이메일로 임시 비밀번호가 발급되었습니다. 로그인을 진행해주세요")
                return window.location.replace("/");
            }
        }).catch((error)=>{
            const statusCode= error.response.status;
            if(statusCode === 401){
                alert("인증시간이 만료되었습니다. 다시 시도해주세요")
            }else{
                alert("전송 오류. 다시 시도해주세요")
            }
            return window.location.replace("/");
        })
    }, []);

    return(
        <>

        </>
    )
}

export default Auth;