import React from "react";

const NextArrow = ({onClick}) => {
    return (
        <div
            className={"js-next d-none d-lg-block u-slick__arrow u-slick__arrow-centered--y rounded-circle fas fa-chevron-right u-slick__arrow-inner u-slick__arrow-inner--right ml-lg-n8 ml-wd-n4"}
            style={{right : '-22px'}}
            onClick={onClick}
        />
    );
}
export default NextArrow;