import {BOARD_DATA_SUCCESS, BOARD_DATA_FAIL, BOARD_TYPE_REQUEST} from "../action/BoardAction";

const initialState = {
    loading: false,
    data: [],
    error: null,
};

const BoardReducer = (state = initialState, action) => {
    if(action.type === BOARD_TYPE_REQUEST){
        return {
            ...state,
            loading: true,
            error: null
        }
    }else if(action.type === BOARD_DATA_SUCCESS){
        return {
            ...state,
            loading: false,
            data: action.payload,
        }
    }else if(action.type === BOARD_DATA_FAIL){
        return {
            ...state,
            loading: false,
            error: action.payload
        }
    }else {
        return state;
    }

}

export default BoardReducer