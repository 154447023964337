import {LOGIN, LOGOUT} from "../action/AuthAction";


// 로그인 초기화
const initialState = {
    isAuthenticated: false,
    token: null,
};

const AuthReducer = (state = initialState, action) => { // state 초기화
    if(action.type === LOGIN){
        return {
            ...state,
            isAuthenticated: true,
            token: action.payload,
        };
    }else if(action.type === LOGOUT){
        return {
            ...state,
            isAuthenticated: false,
            token: null,
        };
    }else {
        return state;
    }
}

export default AuthReducer;