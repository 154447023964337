import React, {useEffect, useRef, useState} from "react";
import { useLocation } from 'react-router-dom';
import {useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import axiosInstanceWithToken from "../../containers/axiosInstanceWithToken";
import DOMPurify from 'dompurify'
import ImageModal from "./ImageModal";
import DefaultImage from "../../assets/image/noimage.jpg";

const apiUrl = process.env.REACT_APP_APIGATEWAY_ENDPOINT;

const AlbumDetail = () => {
    const location = useLocation();
    const { albumCode } = location.state;
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const [album, setAlbum] = useState([]);
    const [minPrice, setMinPrice] = useState(0);
    const [salePrice, setSalePrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedTrack, setSelectedTrack] = useState([]);
    const [selectedArtwork, setSelectedArtwork] = useState([]);
    const [allTracks, setAllTracks] = useState([]);
    const [allArtwork, setAllArtwork] = useState([]);
    const [selectedRadio, setSelectedRadio] = useState('each');
    const [isDisabled, setIsDisabled] = useState(false) // 모든 곡 구매시 아트워크 관련


    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);

    useEffect(() => {
        //window.scrollTo(0, 0);
        let axiosType;
        if(isAuthenticated){
            axiosType = axiosInstanceWithToken;
        }else{
            axiosType = axios;
        }
        axiosType.get(apiUrl + "/product/album", {params: {albumCode: albumCode}})
            .then(response => {
                let albumData;
                if(isAuthenticated){
                    setAlbum(response);
                    albumData = response;
                }else{
                    setAlbum(response.data);
                    albumData = response.data;
                }
                if(albumData.tracks){
                    const tracksPrice = albumData.tracks.map(item => item.trackPrice);
                    const artworksPrice = albumData.artworks.map(item => item.artworkPrice);
                    const tPrice = Math.min(...tracksPrice);
                    const aPrice = Math.min(...artworksPrice);
                    const minimumPrice = Math.min(tPrice, aPrice);
                    setMinPrice(minimumPrice.toLocaleString());
                    
                    const sumTracksPrice = tracksPrice.reduce((acc, price) => acc + price, 0);
                    const sumArtworksPrice = artworksPrice.reduce((acc, price) => acc + price, 0);
                    const allPrice = sumTracksPrice + sumArtworksPrice;
                    setSalePrice((allPrice - albumData.albumPrice).toLocaleString());

                    let initialChecked = {};
                    album.tracks.forEach(track => {
                        initialChecked[track.trackCode] = false;
                    })
                    setAllTracks(initialChecked);
                    initialChecked = {};
                    album.artworks.forEach(artwork => {
                        initialChecked[artwork.artworkCode] = false;
                    })
                    setAllArtwork(initialChecked);
                }
            })
            .catch(error => {

            })
    }, []);

    useEffect(() => {
        // Initialize allTracks  allArtwork
        const initialTracks = {};
        const initialArtwork = {};

        if (album.tracks) {
            album.tracks.forEach(track => {
                initialTracks[track.trackCode] = false;
            });
        }
        if (album.artworks) {
            album.artworks.forEach(artwork => {
                initialArtwork[artwork.artworkCode] = false;
            });
        }

        setAllTracks(initialTracks);
        setAllArtwork(initialArtwork);
    }, [album]);


    const scrollTosection = (e, ref) => {
        e.preventDefault();
        if(ref){
            ref.current.scrollIntoView({behavior: "smooth"});
        }
    }
    const calcSize = (size) => {
        if (size < 1024 * 1024) {
            return Math.floor(size / 10.24) / 100 + "KB"
        } else {
            return Math.floor(size / (1024 * 10.24)) / 100 + "MB"
        }
    }
    const sanitizedDescription = (description) => {
        return DOMPurify.sanitize(description);
    }

    // 체크박스, 셀렉트박스
    const handleTrackCheckboxChange = (e, track) => {
        const { checked } = e.target;
        setAllTracks(prevCheckedItems => {
            let checkCount = 0;
            const updatedCheckedItems = {
                ...prevCheckedItems,
                [track.trackCode]: track.ordering ? false : checked
            };
            const trackChecked = document.getElementsByName('trackCheckbox');
            for(let i =0; i<trackChecked.length; i++) {
                if(trackChecked[i].checked){
                    checkCount ++;
                }
            }
            const ordercount = album.trackordercount;
            // 체크박스 상태 업데이트 후, 전체 선택 여부 확인
            let allChecked = Object.values(updatedCheckedItems).every(value => value === true);
            if(album.trackCount === checkCount + ordercount){
                allChecked = true;
            }

            // 전체 선택되면 'all' 라디오 버튼 선택, 아니면 'each' 선택
            if (allChecked) {
                setSelectedRadio('all');
                const updatedCheckedItems = { ...allArtwork };
                const selectedArtwork = [];

                album.artworks.forEach(artwork => {
                    if(!artwork.ordering){
                        updatedCheckedItems[artwork.artworkCode] = true;
                        selectedArtwork.push(artwork)
                    }
                });
                setAllArtwork(updatedCheckedItems);
                setSelectedArtwork(selectedArtwork);
                setIsDisabled(true);
                setTotalPrice(album.albumPrice)
            } else {
                setSelectedRadio('each');
                setIsDisabled(false);
            }
            return updatedCheckedItems;
        });

        setSelectedTrack(prevSelectedTrack => {
            const updatedTrack = checked
                ? [...prevSelectedTrack, track]
                : prevSelectedTrack.filter(item => item.trackCode !== track.trackCode);

            const totalTrackPrice = updatedTrack.reduce((acc, item) => acc + item.trackPrice, 0);
            const totalArtworkPrice = selectedArtwork.reduce((acc, item) => acc + item.artworkPrice, 0);

            setTotalPrice(totalTrackPrice + totalArtworkPrice);
            return updatedTrack;
        });
    }
    const handleArtworkCheckboxChange = (e, artwork) => {
        const { checked } = e.target;
        setAllArtwork(prevCheckedItems => {
            const updatedCheckedItems = {
                ...prevCheckedItems,
                [artwork.artworkCode]: checked,
            };
            return updatedCheckedItems;
        });

        setSelectedArtwork(prevSelectedArtwork => {
            const updatedArtwork = checked
                ? [...prevSelectedArtwork, artwork]
                : prevSelectedArtwork.filter(item => item.artworkCode !== artwork.artworkCode);

            const totalArtworkPrice = updatedArtwork.reduce((acc, item) => acc + item.artworkPrice, 0);
            const totalTrackPrice = selectedTrack.reduce((acc, item) => acc + item.trackPrice, 0);

            setTotalPrice(totalArtworkPrice + totalTrackPrice);
            return updatedArtwork;
        });
    }

    const handleAllSelect = () => {
        let price = album.albumPrice;
        if(album.tracks){
            const updatedCheckedItems = { ...allTracks };
            const selectedTracks = [];
            album.tracks.forEach(track => {
                if(!track.ordering){
                    updatedCheckedItems[track.trackCode] = true;
                    selectedTracks.push(track);
                }else{
                    price -= track.trackPrice;
                }
            });
            setAllTracks(updatedCheckedItems);
            setSelectedTrack(selectedTracks);
        }
        if(album.artworks){
            const updatedCheckedItems = { ...allArtwork };
            const selectedArtwork = [];
            album.artworks.forEach(artwork => {
                if(!artwork.ordering){
                    updatedCheckedItems[artwork.artworkCode] = true;
                    selectedArtwork.push(artwork)
                }

            });
            setAllArtwork(updatedCheckedItems);
            setSelectedArtwork(selectedArtwork);
        }
        setTotalPrice(price);
        setIsDisabled(true);
    }
    const handleRadioChange = (e) => {
        setSelectedRadio(e.target.value);
    }

    // 오디오 관련
    const handlePreviewPlay = (id) => {
        const audios = document.getElementsByTagName('audio')
        for(let i=0; i<audios.length; i++) {
            if(audios[i].id === id)
                continue;
            audios[i].pause()
            audios[i].currentTime = 0;
        }
        const audio = document.getElementById(id);
        if(audio.paused){
            audio.play();
        }else{
            audio.pause();
            audio.currentTime = 0;
        }
    }
    
    // 이미지 팝업
    const [isOpen, setIsOpen] = React.useState(false);
    const modalOpen = () => {
        setIsOpen(true);
    }

    const imageErrorHandler = (e) => {
        e.target.src = DefaultImage
    }

    // 장바구니 담기
    const basketAdd = () => {
        const trackCheck = selectedTrack.map(item => item.trackCode);
        const artworkCheck = selectedArtwork.map(item => item.artworkCode);
        const productIds = [...trackCheck, ...artworkCheck];
        if(productIds.length === 0){
            alert('상품을 선택해 주세요')
            return
        }
        axiosInstanceWithToken.post(apiUrl + '/basket/add', {'productIds' : productIds})
            .then(response => {
                let result = window.confirm("장바구니 담기 성공! 장바구니로 이동하시겠습니까?")
                if(result){
                    window.location.href = "/basket"
                }
            })
            .catch(error => {
                const statusCode = error.response ? error.response.status : 503;
                if(statusCode !== 401) {
                    alert("장바구니 담기 실패. 다시 시도해 주세요")
                }
            })


    }

    return (
        <div id="primary" className="content-area">
            <main id="main" className="site-main ">
                <div className="product">
                    <div className="container">
                        {/* 상단 구성 */}
                        <div className="row">
                            <div className="col-md-5 woocommerce-product-gallery woocommerce-product-gallery--with-images images ">
                                <figure className="woocommerce-product-gallery__wrapper pt-8 mb-0 pl-5 pr-5">
                                    <img src={album.coverUrl} alt="Image Description" onError={imageErrorHandler}
                                         className="mx-auto img-fluid"/>
                                </figure>
                            </div>
                            <div className="col-md-7 pl-0 summary entry-summary border-left">
                                <div className="space-top-2 px-4 px-xl-7 border-bottom">
                                    <h1 className="product_title entry-title font-size-7 mb-3">{album.albumName}</h1>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="font-size-2 mb-4">
                                                <span className="text-gray-600">{album.albumArtist}</span>
                                            </div>
                                            <div className="font-size-2 mb-4">
                                                <span className="font-weight-medium text-gray-600">{album.publisher}</span>
                                                <span className="ml-3">{album.releaseDate} 발매</span>
                                            </div>
                                            <div className="font-size-2 mb-4">
                                                <p className="text-gray-600">구성</p>
                                                <p className="ml-3">곡 {album.tracks && album.tracks[0].trackName} <span className="text-gray-600">({album.tracks && album.tracks[0].trackFileFormat})</span> 외 {album.trackCount - 1}개</p>
                                                <p className="ml-3">아트워크 {album.artworks && album.artworks[0].artworkName} <span className="text-gray-600">({album.artworks && album.artworks[0].artworkFileFormat})</span> 외 {album.artworks && album.artworks.length -1}개</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="font-size-2 mb-4 ">
                                                <p>개별구매 {minPrice} ~ {album.albumPrice > 0 && album.albumPrice.toLocaleString()} 원</p>
                                                <p>앨범할인 {salePrice} 원</p>
                                                <span className="text-red-1">* 모든 곡 구매 시 아트워크 가격이 할인됩니다.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="align-items-center border-bottom">
                                    <div className="row">
                                        <div className="col-md-4 px-4 py-5 ml-3 px-xl-7 ">
                                            <div className="font-size-2 mb-4">
                                                <input type="radio" id="allSelect" name="albumRadio"
                                                       onClick={handleAllSelect}
                                                       onChange={handleRadioChange}
                                                       value='all'
                                                       checked={selectedRadio === 'all'}
                                                       />
                                                <label htmlFor="allSelect" className="ml-3">앨범 모두 구매</label>
                                            </div>
                                            <div className="font-size-2">
                                                <input type="radio" id="eachSelect" name="albumRadio"
                                                       value='each'
                                                       onChange={handleRadioChange}
                                                       checked={selectedRadio === 'each'}
                                                        />
                                                <label htmlFor="eachSelect" className="ml-3">낱개 구매</label>
                                            </div>
                                        </div>
                                        <div className="col-md-7 border-left" style={{overflow: "auto", height:'147px'}} >
                                            <div className="mt-4 ml-4 pb-3 font-size-2">
                                                <div className="pb-3">
                                                    <span>선택한 곡 총 {selectedTrack.length} 개,</span>
                                                    <span className=" ml-4">아트워크 총 {selectedArtwork.length} 개</span>
                                                </div>

                                                {selectedTrack.length === 0 && selectedArtwork.length === 0 && (
                                                    <p>곡과 아트워크에서 구매하실 작품을 선택해 주세요</p>
                                                )}

                                                { selectedTrack.length !== 0 && selectedTrack.map((tracks) => {
                                                    return (
                                                        <div key={tracks.trackCode}>
                                                            <p>
                                                                <span className="mr-3">{tracks.trackName}</span>
                                                                <span className="mr-3">{tracks.trackFileFormat}</span>
                                                                <span className="mr-3">{calcSize(tracks.trackSize)}</span>
                                                            </p>
                                                        </div>
                                                    )})}
                                                { selectedArtwork.length !== 0 && selectedArtwork.map((artwork) => {
                                                    return (
                                                        <div key={artwork.trackCode}>
                                                            <p>
                                                                <span className="mr-3">{artwork.artworkName}</span>
                                                                <span className="mr-3">{artwork.artworkFileFormat}</span>
                                                                <span className="mr-3">{calcSize(artwork.artworkSize)}</span>
                                                            </p>
                                                        </div>
                                                    )})}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="px-4 px-xl-7 py-5 align-items-center">
                                    <div className="row">
                                    <div className="col-md-8 mb-3 mb-md-0 d-flex align-items-center justify-content-between">
                                        <div className="">
                                            <strong>
                                                <span className="woocommerce-Price-amount amount">구매 금액 : {totalPrice && totalPrice.toLocaleString()}원</span>
                                                <span className="ml-4">(VAT 포함)</span>
                                            </strong>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mr-3">
                                            <button type="submit" name="add-to-cart" value="7145"
                                                    className="btn btn-dark border-0 rounded-0 p-3 single_add_to_cart_button button alt"
                                                    style={{minWidth: '13rem'}}
                                                    onClick={basketAdd}>
                                                장바구니 추가
                                            </button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 앨범 소개 */}
                    <div className="js-scroll-nav mb-10">
                        <div className="woocommerce-tabs wc-tabs-wrapper  2 mx-lg-auto">
                            <div id="Description" className="" ref={section1Ref}>
                                <div className="border-top border-bottom">
                                    <ul className="container tabs wc-tabs nav justify-content-md-center flex-nowrap flex-md-wrap overflow-auto overflow-md-visble">
                                        <li className="flex-shrink-0 flex-md-shrink-1 nav-item active">
                                            <Link role="button" className="nav-link py-4 font-weight-medium active" onClick={(e)=>scrollTosection(e, section1Ref)}>
                                                앨범 소개
                                            </Link>
                                        </li>
                                        <li className="flex-shrink-0 flex-md-shrink-1 nav-item">
                                            <Link className="nav-link py-4 font-weight-medium" onClick={(e)=>scrollTosection(e, section2Ref)}>
                                                수록 곡
                                            </Link>
                                        </li>
                                        <li className="flex-shrink-0 flex-md-shrink-1 nav-item">
                                            <Link className="nav-link py-4 font-weight-medium" onClick={(e)=>scrollTosection(e, section3Ref)}>
                                                아트워크
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="tab-content font-size-2 container">
                                    <div className="row">
                                        <div className="col-xl-8 offset-xl-2">
                                            {/*<div*/}
                                            {/*    className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab pt-9">*/}
                                            {/*    <p>기본안내문</p>*/}
                                            {/*</div>*/}
                                            {album.albumDescriptionImageFileUrl && (
                                                <div
                                                    className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab pt-10">
                                                    <img src={album.albumDescriptionImageFileUrl} alt="Image Description" onError={imageErrorHandler}
                                                         className="mx-auto img-fluid"/>
                                                </div>
                                            )}
                                            <div
                                                className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab pt-9 pb-9"
                                                dangerouslySetInnerHTML={{__html: sanitizedDescription(album.albumDescription)}}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 수록 곡 */}
                            <div id="AlbumTracks" className="" ref={section2Ref}>
                                <div className="border-top border-bottom">
                                <ul className="container tabs wc-tabs nav justify-content-md-center flex-nowrap flex-md-wrap overflow-auto overflow-md-visble">
                                        <li className="flex-shrink-0 flex-md-shrink-1 nav-item ">
                                            <Link role="button" className="nav-link py-4 font-weight-medium "
                                                  onClick={(e) => scrollTosection(e, section1Ref)}>
                                                앨범 소개
                                            </Link>
                                        </li>
                                        <li className="flex-shrink-0 flex-md-shrink-1 nav-item active">
                                            <Link className="nav-link py-4 font-weight-medium active"
                                                  onClick={(e) => scrollTosection(e, section2Ref)}>
                                                수록 곡
                                            </Link>
                                        </li>
                                        <li className="flex-shrink-0 flex-md-shrink-1 nav-item">
                                            <Link className="nav-link py-4 font-weight-medium"
                                                  onClick={(e) => scrollTosection(e, section3Ref)}>
                                                아트워크
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="tab-content font-size-2 container">
                                    <div className="row">
                                        <div className="col-xl-8 offset-xl-2">
                                            <div
                                                className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab pt-9">
                                                <div className="table-responsive mb-4">
                                                    <table className="table table-hover table-borderless">
                                                        <thead>
                                                        <tr className="text-center">
                                                            <th className="width-80">선택</th>
                                                            <th>곡</th>
                                                            <th className="width-80">미리듣기</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {album && album.tracks && album.tracks
                                                            .map((track, index) => {
                                                            const [format, soundQuality] = track.trackFileFormat.split(' ');
                                                            return(
                                                            <tr className="text-center" key={index} >
                                                            <td className="">
                                                                {/*<input type="checkbox"/>*/}
                                                                <li className="custom-control custom-checkbox mt-2">
                                                                    <input type= "checkbox"
                                                                           name = "trackCheckbox"
                                                                           className="custom-control-input"
                                                                           id={`trackCode${index}`}
                                                                           checked={allTracks[track.trackCode] || false}
                                                                           disabled={track.ordering}
                                                                           onChange={(e)=> handleTrackCheckboxChange(e, track)}
                                                                    />
                                                                    <label className="custom-control-label"
                                                                           htmlFor={`trackCode${index}`}></label>
                                                                </li>
                                                            </td>
                                                            <td className="">
                                                                <div>
                                                                    <div className="text-left pb-2">
                                                                        <strong>{track.trackName}</strong>
                                                                    </div>
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-between text-left">
                                                                        <div
                                                                            className="col-md-7 pl-0 text-gray-700 ">
                                                                            <span
                                                                                className="mr-4">{format}</span>
                                                                            <span
                                                                                className="mr-4">{soundQuality}</span>
                                                                            <span
                                                                                className="mr-4">{calcSize(track.trackSize)}</span>
                                                                        </div>
                                                                        <div className="col-md-3 text-right">
                                                                            {track.trackPrice.toLocaleString()} 원
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="">
                                                                <div className="mt-3">
                                                                    <button className="bg-white border-0" onClick={() => handlePreviewPlay(`preview${index}`)}>
                                                                        <i className="fas fa-volume-up"></i>
                                                                    </button>
                                                                    <audio id={`preview${index}`} controls
                                                                           src={track.trackPreviewFileUrl}
                                                                           type="audio/ogg" hidden/>
                                                                </div>
                                                            </td>
                                                            </tr>
                                                            )
                                                            })}
                                                        </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 아트워크 */}
                            <div id="AlbumArtworks" className="" ref={section3Ref}>
                                <div className="border-top border-bottom">
                                    <ul className="container tabs wc-tabs nav justify-content-md-center flex-nowrap flex-md-wrap overflow-auto overflow-md-visble">
                                        <li className="flex-shrink-0 flex-md-shrink-1 nav-item">
                                            <Link role="button" className="nav-link py-4 font-weight-medium"
                                                  onClick={(e) => scrollTosection(e, section1Ref)}>
                                                앨범 소개
                                            </Link>
                                        </li>
                                        <li className="flex-shrink-0 flex-md-shrink-1 nav-item">
                                            <Link className="nav-link py-4 font-weight-medium"
                                                  onClick={(e) => scrollTosection(e, section2Ref)}>
                                                수록 곡
                                            </Link>
                                        </li>
                                        <li className="flex-shrink-0 flex-md-shrink-1 nav-item active">
                                            <Link className="nav-link py-4 font-weight-medium active"
                                                  onClick={(e) => scrollTosection(e, section3Ref)}>
                                                아트워크
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="tab-content font-size-2 container">
                                    <div className="row">
                                        <div className="col-xl-8 offset-xl-2">
                                            <div
                                                className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab pt-9">
                                                <div className="table-responsive mb-4">
                                                    <table className="table table-hover table-borderless">
                                                        <thead>
                                                        <tr className="text-center">
                                                            <th className="width-80">선택</th>
                                                            <th>아트워크</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {album && album.artworks && album.artworks
                                                            .map((artwork, index) => (
                                                                <tr className="text-center" key={index}>
                                                                    <td className="">
                                                                        {/*<input type="checkbox"/>*/}
                                                                        <li className="custom-control custom-checkbox ">
                                                                            <input type="checkbox"
                                                                                   className="custom-control-input"
                                                                                   id={`artworkCode${index}`}
                                                                                   checked={allArtwork[artwork.artworkCode] || false}
                                                                                   disabled={artwork.ordering || isDisabled}
                                                                                   onChange={(e)=> handleArtworkCheckboxChange(e, artwork)}
                                                                            />
                                                                            <label className="custom-control-label"
                                                                                   htmlFor={`artworkCode${index}`}></label>
                                                                        </li>
                                                                    </td>
                                                                    <td className="">
                                                                        <div>
                                                                            <div
                                                                                className="d-flex align-items-center justify-content-between text-left">
                                                                                <div className="col-md-6 pl-0  ">
                                                                                    <strong>{artwork.artworkName}</strong>
                                                                                </div>
                                                                                <div className="col-md-6 text-gray-700">
                                                                                    <span
                                                                                        className="mr-4">{artwork.artworkFileFormat}</span>
                                                                                    <span className="mr-4">{calcSize(artwork.artworkSize)}</span>
                                                                                    <span className="mr-4">{artwork.artworkPrice.toLocaleString()} 원</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-left pt-3"
                                                                             dangerouslySetInnerHTML={{__html: sanitizedDescription(artwork.artworkComment)}}>
                                                                        </div>
                                                                        <div>
                                                                            <img onClick={modalOpen} src={artwork.artworkPreviewFileUrl} height="125" onError={imageErrorHandler}/>
                                                                            {isOpen && (<ImageModal
                                                                                fileUrl={artwork.artworkPreviewScopeFileUrl}
                                                                                open={isOpen}
                                                                                onClose={() => {
                                                                                    setIsOpen(false);
                                                                                }}/>)}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default AlbumDetail;