import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

const PaymentFail = (cause) => {
    const navigate = useNavigate();

    const paymentFailProcess = () => {
        let msg = ''
        if(cause.cause === 'auth') {
            msg = '결제승인에 실패했습니다. 장바구니로 이동합니다.'
        } else if(cause.cause === 'unknown') {
            msg = '알 수 없는 오류로 결제에 실패했습니다. 장바구니로 이동합니다.'
        } else  if(cause.cause === 'req') {
            msg= '결제요청에 실패했습니다. 장바구니로 이동합니다.'
        }
        alert(msg)
        navigate('/basket')
    }

    useEffect(() => {
        paymentFailProcess()
    }, []);

    return (
        <div className="App">
            {/*<div className="space-2 space-md-3 space-lg-4 space-top-xl-4 space-bottom-xl-4">*/}
            {/*    <div className="d-flex flex-column align-items-center">*/}
            {/*        <div className="font-weight-medium font-size-15 font-size-xs-25 mb-3">결제에 실패했습니다.*/}
            {/*        </div>*/}
            {/*        <h6 className="font-weight-medium mb-2 col-lg-7 text-center text-lh-lg mb-5">아래 링크를 클릭하면 장바구니로 이동됩니다.</h6>*/}
            {/*        <Link to={'/basket'}>장바구니로 이동</Link>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
};

export default PaymentFail;