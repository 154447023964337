import React, {useEffect} from 'react';

import store from "./store/store";
import {loginAction} from "./store/action/AuthAction";
import {Footer, Header, Body} from "./components/Layout";
import { BrowserRouter  } from 'react-router-dom';

const token = localStorage.getItem('authorization');
if (token) {
    store.dispatch(loginAction(token));
}


const App = () => {

    useEffect(() => {
        fetch(process.env.REACT_APP_FRONTEND)
            // .then(res => res.json())
            // .then(data => console.log(data))
            .catch(error => console.error('서버 요청 에러:', error));
    }, []);

    return (
        <>

            <BrowserRouter>
                <Header />
                <Body/>
                <Footer />
            </BrowserRouter>

        </>
    );
}

export default App;
